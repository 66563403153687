export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PROMOTION.IMPRESSION, ({ promotion }) => {
    window.dataLayer.push({
      event: 'view_promotion',
      ecommerce: {
        items: [
          {
            item_id: promotion.name,
            item_name: promotion.name,
          },
        ],
      },
    })
  })
}
