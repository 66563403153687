import { merge } from 'lodash'
import themeSettings from '~/themes/virtshop/settings'

const overloadSettings = {
  product: {
    price: {
      summaryTotalPrimary: {
        decimal: 2,
      },
      summaryTotalSecondary: {
        decimal: 2,
      },
    },
  },
  components: {
    AddToCartModalItemAdded: {
      displayedProducts: 'recommended',
    },
    BranchSelectorPPL: {
      countriesAvailable: 'sk',
    },
    CartInfoDropDownContent: {
      showFreeGift: true,
    },
    EntryModal: {
      enable: false,
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageCheckoutItems: {
      showAvailableGifts: true,
    },
    PageCheckoutMethods: {
      enableInsurance: false,
    },
    PageHomepage: {
      showHomepageArticles: false,
      showHomepageIntimateAdvisory: false,
    },
    PageProductDetail: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
      showAvailabilityInWarehouses: false,
      showAvailabilityInWarehousesInDeliveryOptions: false,
    },
  },
  global: {
    showProductsRecommended: true,
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: ['mobile', 'tablet'],
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
