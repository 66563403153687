import { actions as parentActions } from '~/sites/shop/store/banner'
import gql from 'graphql-tag'

export const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_BANNERS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            banner_wide(filters: { enabled: true }) {
              entities {
                banner_wide_button_text
                banner_wide_text
                banner_wide_text_2
                main_image {
                  path
                  alt
                }
                main_image_translatable {
                  path
                  alt
                }
                title
                url
                weight
                time_limited
                time_limit_start
                time_limit_end
                item_id
                item_name
              }
            }
          }
        `,
      })
      return query.data.banner_wide.entities
        .filter(banner => {
          if (!banner.time_limited) {
            return true
          } else if (banner.time_limited && banner.time_limit_start !== null && banner.time_limit_end !== null) {
            return (
              new Date(banner.time_limit_start).valueOf() < Date.now() &&
              Date.now() < new Date(banner.time_limit_end).valueOf()
            )
          }
          return false
        })
        .sort((a, b) => (b.weight || 0) - (a.weight || 0))
    } catch (e) {
      console.error(e)
    }
  },
}
