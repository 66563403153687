export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PROMOTION.CLICK, ({ promotion }) => {
    window.dataLayer.push({
      event: 'select_promotion',
      ecommerce: {
        items: [
          {
            item_id: promotion.id,
            item_name: promotion.name,
          },
        ],
      },
    })
  })
}
